/* eslint-disable jsx-a11y/anchor-has-content */
import React, { } from "react";
import "./textColImgCol-block.scss";

interface Props {
  children: React.ReactNode,
  img: string;
  reverse?: boolean;
  alignTop?: boolean;
  map?: React.ReactNode;
}

const TextColImgColBlock: React.FC<Props> = (props: Props) => {
  const { children, img, reverse, alignTop, map } = props;

  return (
    <div className={`textColImgCol-block${reverse ? " isReverse" : ""}${alignTop ? " alignTop" : ""}`}>
      <div className="colLeft">
        {children}
      </div>
      {map ?
        <div className="colRight">{map}</div>
        : <div className="colRight" style={{ backgroundImage: `url(${img})` }}></div>}
    </div>
  )
}

export default TextColImgColBlock;