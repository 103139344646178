import "./App.scss";
import React from "react";
import { Switch, Route, withRouter } from "react-router-dom";
import { History } from 'history';
import CookiePolicyPopup from "./framework/components/cookie-policy-popup/cookie-policy-popup";
import { RoutePath } from "./route-paths";
import HomePage from "./framework/pages/home-page/home-page";
import { compose } from "redux";
import { connect, shallowEqual, useSelector } from "react-redux";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import { RulesPage } from "./framework/pages/rules-page/rules-page";
import SlideOverlay from "./framework/components/slide-overlay/slide-overlay";
import PreFooter from "./framework/components/prefooter/prefooter";
import Footer from "./framework/components/footer/footer";
import FixedActionButtons from "./framework/components/fixed-action-buttons/fixed-action-buttons";
import Navbar from "./framework/components/navbar/navbar";
import OFirmiePage from "./framework/pages/arturGrzyb-page/arturGrzyb-page";
import KruszywaPage from "./framework/pages/uslugi-page/kruszywa-page";
import OFirmieBetonPage from "./framework/pages/ofirmieBeton-page/ofirmieBeton-page";
import { select_is_navbarOpenActive } from "./framework/redux/callbacksState/callbacksState.selectors";
import UtylizacjaPage from "./framework/pages/uslugi-page/utylizacja-page";
import TransportNiskPage from "./framework/pages/uslugi-page/transport-nisko-page";
import TransportPage from "./framework/pages/uslugi-page/transport-page";
import TranSprzPage from "./framework/pages/uslugi-page/transprz-page";
import WodKanPage from "./framework/pages/uslugi-page/wodkan-page";
import DrogPage from "./framework/pages/uslugi-page/drog-page";
import RozbPage from "./framework/pages/uslugi-page/rozb-page";
import ZiemPage from "./framework/pages/uslugi-page/ziem-page";

interface Props {
  match: any;
  history?: History;
}

const App: React.FC<Props> = () => {
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive, shallowEqual);

  return (
    <>
      <SlideOverlay />
      <FixedActionButtons />
      <Navbar />
      <div className={`overlay${is_navbarOpenActive ? " isActive" : ""}`}>
        <Switch>
          <Route exact path={RoutePath.RULES} render={() => <RulesPage />} />
          <Route exact path={RoutePath.ARTURGRZYB} render={() => <OFirmiePage />} />
          <Route exact path={RoutePath.BETON} render={() => <OFirmieBetonPage />} />
          <Route exact path={RoutePath.KRUSZYWA} render={() => <KruszywaPage />} />
          <Route exact path={RoutePath.USLUGIUTYLIZACJI} render={() => <UtylizacjaPage />} />
          <Route exact path={RoutePath.TRANSPORTNISKOPODWOZIOWY} render={() => <TransportNiskPage />} />
          <Route exact path={RoutePath.USLUGITRANSPORTOWE} render={() => <TransportPage />} />
          <Route exact path={RoutePath.USLUGITRANSPORTOWOSPRZETOWE} render={() => <TranSprzPage />} />
          <Route exact path={RoutePath.USLUGIWODNOKANALIZACYJNE} render={() => <WodKanPage />} />
          <Route exact path={RoutePath.ROBOTYDROGOWE} render={() => <DrogPage />} />
          <Route exact path={RoutePath.ROBOTYROZBIORKOWE} render={() => <RozbPage />} />
          <Route exact path={RoutePath.ROBOTYZIEMNE} render={() => <ZiemPage />} />
          <Route exact path={RoutePath.HOME} render={() => <HomePage />} />
        </Switch>
      </div>
      <PreFooter />
      <Footer />
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <CookiePolicyPopup />
    </>
  );
}

export default compose(
  withRouter,
  connect(null, null)
)(App);