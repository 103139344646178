import React, { useEffect } from "react";
import "./uslugi-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/utylizacja.jpg";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import { useDispatch } from "react-redux";
import { setNavbarBar } from "../../redux/callbacksState/callbacksState.actions";
import Container from "../../components/container/container";
import TextColImgColBlock from "../../components/textColImgCol-block/textColImgCol-block";
import RedHeroSection from "../../components/redHero-section/redHero-section";

import UtylIcon from "../../assets/icons/utylizacja.svg";

const UtylizacjaPage: React.FC<any> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarBar(true));
    return () => {
      dispatch(setNavbarBar(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="uslugi-page">
      <Helmet>
        <title>{'FTS Artur Grzyb | USŁUGI UTYLIZACJI'}</title>
      </Helmet>
      <RedHeroSection icon={UtylIcon} iconAlt={"USŁUGI UTYLIZACJI"}>
        <TopLink to={RoutePath.ARTURGRZYB}>
          <p className="heroPath">FTS ARTUR GRZYB /</p>
        </TopLink>
        <h2 className="heroTitle">USŁUGI UTYLIZACJI</h2>
      </RedHeroSection>
      <Container>
        <section>
          <TextColImgColBlock img={BgA} alignTop={true}>
            <p>
              W swojej ofercie posiadamy usługi związane z utylizacją odpadów, które powstają w wyniku przeprowadzania przeróżnych prac i robót:
            </p>
            <br />
            <ul>
              <li>drogowych</li>
              <li>budowlanych</li>
              <li>rozbiórkowych</li>
              <li>robót wodno-kanalizacyjnych</li>
            </ul>
            <br />
            <p>Utylizacje odpadów są prowadzone w miejscowościach Karszów i Nowica.</p>
            <br />
            <p>
              Ponadto jesteśmy w stanie zaoferować utylizację odpadów:
            </p>
            <br />
            <ul style={{ listStyle: "decimal-leading-zero" }}>
              <li>Odpady z wydobywania kopalin innych niż rudy metali</li>
              <li>Odpady skalne z górnictwa miedzi, cynku i ołowiu</li>
              <li>Odpady żwiru lub skruszone skały inne niż wymienione w 01 04 07</li>
              <li>Odpadowe piaski i iły</li>
              <li>Odpady powstające przy płukaniu i oczyszczeniu kopalin inne niż wymienione w 01 04 07 i 01 04 11</li>
              <li>Odpady powstające przy cięciu i obróbce postaciowej skał inne niż wymienione w 01 04 07</li>
              <li>Ziemia sucha, ziemia mokra, w tym kamienie</li>
              <li>Stałe osady z oczyszczenia i mycia buraków</li>
              <li>Żużle szybowe i granulowe</li>
              <li>Rdzenie i formy odlewnicze po procesie odlewania inne niż wymienione w 10 09 07</li>
              <li>Rdzenie i formy odlewnicze przed procesem odlewania inne niż wymienione w 10 10 05</li>
              <li>Rdzenie i formy odlewnicze po procesie odlewania inne niż wymienione w 10 10 07</li>
              <li>Odpady z przygotowania mas wsadowych do obróbki terminczej</li>
              <li>Zużyte formy</li>
              <li>Wybrakowane wyroby ceramiczne, cegły, kafle i ceramika budowlana (po przeróbce termicznej)</li>
              <li>Ziemia, piasek, miał z czyszczenia wagonów dostarczających złom</li>
              <li>Okładziny piecowe i materiały ogniotrwałe z procesów metalurgicznych inne niż wymienione w 16 11 03</li>
              <li>Odpady betonu oraz gruz betonowy z rozbiórek i remontów</li>
              <li>Gruz ceglany</li>
              <li>Odpady innych materiałów ceramicznych i elementów wyposażenia (wykonane z ceramiki)</li>
              <li>Zmieszane odpady betonu, gruzu ceglanego, odpadowych materiałów ceramicznych i inne niż wymienione w 17 01 06</li>
              <li>Gleba i ziemia, w tym kamienie, inne niż wymienione w 17 01 06</li>
              <li>Minerały (np. piasek, kamienie)</li>
            </ul>
          </TextColImgColBlock>
        </section>
      </Container>
    </div>
  )
}

export default UtylizacjaPage;