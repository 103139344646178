import React, { useEffect } from "react";
import "./uslugi-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/bg4.jpg";
import Krusz from "../../assets/krusz.jpg";

import Mountain from "../../assets/icons/mountainIcon.svg";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import { useDispatch } from "react-redux";
import { setNavbarBar } from "../../redux/callbacksState/callbacksState.actions";
import MainHeader from "../../components/main-header/main-header";
import Container from "../../components/container/container";
import TextColImgColBlock from "../../components/textColImgCol-block/textColImgCol-block";
import RedHeroSection from "../../components/redHero-section/redHero-section";
import FullImgBlock from "../../components/fullImg-block/fullImg-block";

const KruszywaPage: React.FC<any> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarBar(true));
    return () => {
      dispatch(setNavbarBar(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="uslugi-page">
      <Helmet>
        <title>{'FTS Artur Grzyb | Kopalnie kruszywa'}</title>
      </Helmet>
      <RedHeroSection icon={Mountain} iconAlt={"Mountain"}>
        <TopLink to={RoutePath.ARTURGRZYB}>
          <p className="heroPath">FTS ARTUR GRZYB /</p>
        </TopLink>
        <h2 className="heroTitle">KOPALNIE KRUSZYWA</h2>
      </RedHeroSection>
      <Container>
        <section>
          <MainHeader align={'left'}>
            ZAKŁAD GÓRNICZY “NOWICA”
          </MainHeader>
          <TextColImgColBlock
            img={BgA}
            map={<iframe className="map" title="kopalnia-nowica" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9987.281685684078!2d17.3674073!3d51.2592157!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xfa3d78682a4f9ac6!2sKopalnia%20Piasku%20Nowica!5e0!3m2!1spl!2spl!4v1602862850979!5m2!1spl!2spl" frameBorder="0" style={{ border: 0 }} allowFullScreen={false} aria-hidden="false" tabIndex={0}></iframe>}
          >
            <p>
              Kopalnia znajduję się w miejscowości Nowica, koło Oleśnicy w odległości ok. 27 km od Wrocławia.
            </p>
            <br />
            <p>
              Oferujemy:
            </p>
            <br />
            <ul>
              <li>
                piasek 0-2
              </li>
              <li>
                żwir 2-8
              </li>
              <li>
                żwir 8-16
              </li>
              <li>
                podsypkę
              </li>
              <li>
                humus (ziemia ogrodowa)
              </li>
            </ul>
            <br />
            <p className="smallText">
              *ceny do negocjacji
            </p>
          </TextColImgColBlock>
        </section>
        <section>
          <MainHeader align={'left'}>
            ZAKŁAD GÓRNICZY “KARSZÓW”
          </MainHeader>
          <TextColImgColBlock img={BgA}
            map={<iframe className="map" title="kopalnia-karszow" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1988.6948661145118!2d16.98039033670973!3d50.76158281442797!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470e2cc4d2123195%3A0x411732420fc1bc42!2sKopalnia%20Piasku%20Karsz%C3%B3w!5e0!3m2!1spl!2spl!4v1602862216293!5m2!1spl!2spl" frameBorder="0" style={{ border: 0 }} allowFullScreen={false} aria-hidden="false" tabIndex={0}></iframe>}
          >
            <p>
              Kopalnia znajduję się w miejscowości Karszów,  ok. 8 km. od Strzelina.
            </p>
            <br />
            <p>
              Oferujemy:
            </p>
            <br />
            <ul>
              <li>
                piasek kopany
              </li>
              <li>
                podsypkę
              </li>
              <li>
                humus (ziemia ogrodowa)
              </li>
              <li>
                pospółkę
              </li>
              <li>
                piasek O/2
              </li>
            </ul>
            <br />
            <p className="smallText">
              *ceny do negocjacji
            </p>
          </TextColImgColBlock>
          <FullImgBlock img={Krusz} alt={"Kopalnia kruszywa"} />
        </section>
      </Container>
    </div>
  )
}

export default KruszywaPage;