import React, { useEffect } from "react";
import "./uslugi-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/wodkan.jpg";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import { useDispatch } from "react-redux";
import { setNavbarBar } from "../../redux/callbacksState/callbacksState.actions";
import Container from "../../components/container/container";
import TextColImgColBlock from "../../components/textColImgCol-block/textColImgCol-block";
import RedHeroSection from "../../components/redHero-section/redHero-section";

import WodnIcon from "../../assets/icons/wodnokanalizacyjne.svg";

const WodKanPage: React.FC<any> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarBar(true));
    return () => {
      dispatch(setNavbarBar(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="uslugi-page">
      <Helmet>
        <title>{'FTS Artur Grzyb | USŁUGI WODNO - KANALIZACYJNE'}</title>
      </Helmet>
      <RedHeroSection icon={WodnIcon} iconAlt={"USŁUGI WODNO - KANALIZACYJNE"}>
        <TopLink to={RoutePath.ARTURGRZYB}>
          <p className="heroPath">FTS ARTUR GRZYB /</p>
        </TopLink>
        <h2 className="heroTitle">USŁUGI WODNO - KANALIZACYJNE</h2>
      </RedHeroSection>
      <Container>
        <section>
          <TextColImgColBlock img={BgA} alignTop={true}>
            <p>
            Jedną z dziedzin, w której wyspecjalizowała się nasza firma jest szeroka działalność w zakresie usług wodno-kanalizacyjnych. Realizując przez wiele lat przeróżne projekty dla wielu gmin z rejonu oleśnickiego, województwa dolnośląskiego, opolskiego, wielkopolskiego oraz innych, zdobyliśmy dużo doświadczenia. Nasz wykwalifikowany personel i baza sprzętowo - maszynowa pozwala w bardzo dokładny sposób wykonać budowę sieci wodno - kanalizacyjnych.
            </p>
          </TextColImgColBlock>
        </section>
      </Container>
    </div>
  )
}

export default WodKanPage;