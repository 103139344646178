/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import Container from "../container/container";
import "./redHero-section.scss";

interface Props {
  children: React.ReactNode;
  icon: string;
  iconAlt: string;
}

const RedHeroSection: React.FC<Props> = (props: Props) => {
  const { children, icon, iconAlt } = props;
  return (
    <section className="redhero-section">
      <Container>
        <div className="contentCol">
          {children}
        </div>
        <div className="iconCol">
          <img src={icon} alt={iconAlt ? iconAlt : ""} />
        </div>
      </Container>
    </section>
  )
}

export default RedHeroSection;