/* eslint-disable jsx-a11y/anchor-has-content */
import React, { } from "react";
import "./action-contact-section.scss";

interface Props { }

const ActionContactSection: React.FC<Props> = () => {
  return (
    <section className="action-contact-section" >
      <div className="actionContactBelt">
        <a className="topLinkWrapper" href="mailto:beton@ftsgrzyb.pl">
          SKONTAKTUJ SIĘ Z NAMI<span>&rarr;</span>
        </a>
      </div>
    </section>
  )
}

export default ActionContactSection;