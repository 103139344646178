export class RoutePath {
  [key: string]: any;
  static RULES: string = '/warunki-uzytkowania';
  static ARTURGRZYB: string = '/fts-artur-grzyb';
  static BETON: string = '/fts-beton';
  static KRUSZYWA: string = '/kopalnia-kruszywa';
  static USLUGIUTYLIZACJI: string = '/uslugi-utylizacji';
  static TRANSPORTNISKOPODWOZIOWY: string = '/transport-niskopodwoziowy';
  static USLUGITRANSPORTOWE: string = '/uslugi-transportowe';
  static USLUGITRANSPORTOWOSPRZETOWE: string = '/uslugi-transportowo-sprzetowe';
  static USLUGIWODNOKANALIZACYJNE: string = '/uslugi-wodno-kanalizacyjne';
  static ROBOTYDROGOWE: string = '/roboty-drogowe';
  static ROBOTYROZBIORKOWE: string = '/roboty-rozbiorkowe';
  static ROBOTYZIEMNE: string = '/roboty-ziemne';
  static HOME: string = '/';
}