import "./cookie-policy-popup.scss";
import React, { Component } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { selectIsCookiePopupVisible } from "../../redux/cookiePolicyPopup/cookiePolicyPopup.selectors";
import { hideCookiePolicyPopup } from "../../redux/cookiePolicyPopup/cookiePolicyPopup.actions";
import { RoutePath } from "../../../route-paths";
import { Link } from "react-router-dom";
import { FtsBetonStore } from "../../redux/store.types";

interface DerivedProps {
  isCookiePopupVisible: any
  hideCookiePolicyPopup?: any;
}

class CookiePolicyPopup extends Component<DerivedProps, never> {

  handleCancel = () => {
    this.props.hideCookiePolicyPopup();
  }

  render() {
    const { isCookiePopupVisible } = this.props;
    return (
      <>
        {isCookiePopupVisible ?
          <div className="cookies-info-box">
            <div className="left-side">
              <h3>Polityka Prywatności i Cookies</h3>
              <p>Używamy technologi plików cookies, aby analizować ruch na naszej stronie. Przeglądając stronę, akceptujesz warunki użytkowania i&nbsp;politykę prywatności. W&nbsp;każdej chwili możesz zmienić zdanie i&nbsp;wyłączyć pliki cookies w przeglądarce.</p>
            </div>
            <div className="right-side">
              <div className="btn-material-nav" onClick={this.handleCancel}>Akceptuję</div>
              <Link to={RoutePath.RULES}>
                <div className="privacy">Szczegóły</div>
              </Link>
            </div>
          </div>
          : null
        }
      </>
    )
  }
}

const mapStateToProps = createStructuredSelector<FtsBetonStore, DerivedProps>({
  isCookiePopupVisible: selectIsCookiePopupVisible
});

const mapDispatchToProps = (dispatch: any) => ({
  hideCookiePolicyPopup: () => dispatch(hideCookiePolicyPopup())
})

export default connect(mapStateToProps, mapDispatchToProps)(CookiePolicyPopup);