import React, { useEffect } from "react";
import "./uslugi-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/trans.jpg";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import { useDispatch } from "react-redux";
import { setNavbarBar } from "../../redux/callbacksState/callbacksState.actions";
import Container from "../../components/container/container";
import TextColImgColBlock from "../../components/textColImgCol-block/textColImgCol-block";
import RedHeroSection from "../../components/redHero-section/redHero-section";

import TranIcon from "../../assets/icons/transport.svg";

const TransportPage: React.FC<any> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarBar(true));
    return () => {
      dispatch(setNavbarBar(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="uslugi-page">
      <Helmet>
        <title>{'FTS Artur Grzyb | USŁUGI TRANSPORTOWE'}</title>
      </Helmet>
      <RedHeroSection icon={TranIcon} iconAlt={"USŁUGI TRANSPORTOWE"}>
        <TopLink to={RoutePath.ARTURGRZYB}>
          <p className="heroPath">FTS ARTUR GRZYB /</p>
        </TopLink>
        <h2 className="heroTitle">USŁUGI TRANSPORTOWE</h2>
      </RedHeroSection>
      <Container>
        <section>
          <TextColImgColBlock img={BgA} alignTop={true}>
            <p>
              Jesteśmy w stanie zapewnić transport materiałów budowlanych, kruszywa, elementów wielkogabarytowych, elementów wymiarowych oraz innych.
              Jeśli chcesz skorzystać z oferty transportu, skontaktuj się z nami.
            </p>
            <br />
            <p>
              Oferujemy:
            </p>
            <br />
            <ul>
              <li>Transport ciężarowy samochodami 4-osiowymi: 8x8, 8x6, 8x4</li>
              <li>Transport ciężarowy samochodami 3-osiowymi: 6x6, 6x4</li>
              <li>Transport niskopodwoziowy : 47 ton oraz 25 ton</li>
              <li>Transport naczepami skrzyniowymi</li>
              <li>Ciągniki siodłowe + naczepy (wywrotki)</li>
            </ul>
          </TextColImgColBlock>
        </section>
      </Container>
    </div>
  )
}

export default TransportPage;