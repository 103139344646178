import { createSelector } from 'reselect';
import { FtsBetonStore } from '../store.types';

const callbacksState = (state: FtsBetonStore) => state.callbacksState;

export const selectIs_globalToastFailedToFetchActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_globalToastFailedToFetchActive
  }
);

export const select_is_slideOverlayActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_slideOverlayActive
  }
);

export const select_is_navbarBarActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_navbarbarActive
  }
);

export const select_is_navbarOpenActive = createSelector(
  [callbacksState],
  callbacksState => {
    return callbacksState.is_navbarOpenActive
  }
);