import React from "react";
import "./top-link.scss";
import { useDispatch } from "react-redux";
import { showSlideOverlay } from "../../redux/callbacksState/callbacksState.actions";

interface Props {
  to: string,
  children: React.ReactNode | string
}

const TopLink: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  const slideAndScroll = () => {
    dispatch(showSlideOverlay(props.to as string))
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 200);
  }

  return (
    <div onClick={slideAndScroll} className="topLinkWrapper">{props.children}</div>
  )
}

export default TopLink;
