import React, { useEffect } from "react";
import "./uslugi-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/trsp.jpg";
import BgB from "../../assets/trspbg.jpg";

import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import { useDispatch } from "react-redux";
import { setNavbarBar } from "../../redux/callbacksState/callbacksState.actions";
import Container from "../../components/container/container";
import TextColImgColBlock from "../../components/textColImgCol-block/textColImgCol-block";
import RedHeroSection from "../../components/redHero-section/redHero-section";

import TsprIcon from "../../assets/icons/transsprzety.svg";
import FullImgBlock from "../../components/fullImg-block/fullImg-block";

const TranSprzPage: React.FC<any> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarBar(true));
    return () => {
      dispatch(setNavbarBar(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="uslugi-page">
      <Helmet>
        <title>{'FTS Artur Grzyb | USŁUGI TRANSPORTOWO - SPRZĘTOWE'}</title>
      </Helmet>
      <RedHeroSection icon={TsprIcon} iconAlt={"USŁUGI TRANSPORTOWO - SPRZĘTOWE"}>
        <TopLink to={RoutePath.ARTURGRZYB}>
          <p className="heroPath">FTS ARTUR GRZYB /</p>
        </TopLink>
        <h2 className="heroTitle">USŁUGI TRANSPORTOWO - SPRZĘTOWE</h2>
      </RedHeroSection>
      <Container>
        <section>
          <TextColImgColBlock img={BgA} alignTop={true}>
            <p>
              Interesuje Cię wynajęcie sorzętu, takiego jak koparka, spych itp? Skontaktuj się z nami. Możemy zaoferować usługi sprzętowe wraz z transportem. Oferujemy usługi sprzętowe z zakresu:
            </p>
            <br />
            <ul>
              <li>Koparko- ładowarki</li>
              <li>Koparki gąsiennicowe</li>
              <li>Koparki kołowe</li>
              <li>Ładowarki</li>
              <li>Spycharki</li>
              <li>Mini koparki</li>
              <li>Mini ładowarki</li>
              <li>Walce</li>
              <li>Zagęszarki oraz sprzęt drobny (np. igłofiltry)</li>
            </ul>
          </TextColImgColBlock>
          <FullImgBlock img={BgB} />
        </section>
      </Container>
    </div>
  )
}

export default TranSprzPage;