import React, { useEffect } from "react";
import "./uslugi-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/drog.jpg";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import { useDispatch } from "react-redux";
import { setNavbarBar } from "../../redux/callbacksState/callbacksState.actions";
import Container from "../../components/container/container";
import TextColImgColBlock from "../../components/textColImgCol-block/textColImgCol-block";
import RedHeroSection from "../../components/redHero-section/redHero-section";

import DrogIcon from "../../assets/icons/drogowe.svg";

const DrogPage: React.FC<any> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarBar(true));
    return () => {
      dispatch(setNavbarBar(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="uslugi-page">
      <Helmet>
        <title>{'FTS Artur Grzyb | ROBOTY DROGOWE'}</title>
      </Helmet>
      <RedHeroSection icon={DrogIcon} iconAlt={"ROBOTY DROGOWE"}>
        <TopLink to={RoutePath.ARTURGRZYB}>
          <p className="heroPath">FTS ARTUR GRZYB /</p>
        </TopLink>
        <h2 className="heroTitle">ROBOTY DROGOWE</h2>
      </RedHeroSection>
      <Container>
        <section>
          <TextColImgColBlock img={BgA} alignTop={true}>
            <p>
              Jedną z pierwszych dziedzin, w których się wyspecjalizowaliśmy były robotowy drogowe. Ta dziedzina budownictwa jest u nas od samego początku - dzięki temu jesteśmy w stanie zaoferować najlepszą jakość wykonywanych usług. Swoje doświadczenie zdobywaliśmy  na drogach w rejonie dolnegośląska, wielkopolski, opolszczyzny oraz wielu innych.
            </p>
          </TextColImgColBlock>
        </section>
      </Container>
    </div>
  )
}

export default DrogPage;