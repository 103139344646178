import React from "react";
import "./ofirmieBeton-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/miniBg.jpg";
import BgB from "../../assets/bg5.jpg";
import SubLogoA from "../../assets/ftsBeton.jpg";
import FullMapSection from "../../components/full-map-section/full-map-section";
import CollapsibleBlock from "../../components/collapsible-block/collapsible-block";
import MainHeader from "../../components/main-header/main-header";
import TextColImgColBlock from "../../components/textColImgCol-block/textColImgCol-block";
import Container from "../../components/container/container";
import HeroSection from "../../components/hero-section/hero-section";
import Bloczki from "../../assets/bloczki.jpg";

const OFirmieBetonPage: React.FC<any> = () => {

  return (
    <div className="ofirmieBeton-page">
      <Helmet>
        <title>{'FTS Beton'}</title>
      </Helmet>

      <HeroSection bg={BgB}>
        <img src={SubLogoA} alt="FTS Beton" />
      </HeroSection>

      <section>
        <Container>
          <MainHeader>
            O FIRMIE
        </MainHeader>
          <TextColImgColBlock img={BgA} reverse>
            Firma Transportowo-Sprzętowa Artur Grzyb , która swoją działalność prowadzi od 1992r. -  w 2020r  działając już w szerokim sektorze budowlanym postanowiła dołączyć do swojej oferty produkcje betonów,  , stawiając nowoczesny węzeł betoniarski wraz z zapleczem techniczno-sprzętowym , które działa pod szyldem „ FTS Beton”
        </TextColImgColBlock>
        </Container>
      </section>

      <section>
        <Container>
          <MainHeader>
            OFERTA
          </MainHeader>
          <div className="collapsibleBlocksWrapper">
            <CollapsibleBlock title="BETON TOWAROWY">
              <p>
                <strong>Betony Towarowe</strong> - jest to beton produkowany zgodnie z obowiązującymi normami, powstały w wyniku wymieszania kruszywa, wody, cementu oraz domieszek. Jego zastosowanie to stropy, ławy fundamentowe, płyty betonowe, itd
              </p>
              <p className="codesWrapper">
                <code>C8/10</code>
                <code>C12/15</code>
                <code>C16/20</code>
                <code>C20/25</code>
                <code>C25/30</code>
                <code>C30/37</code>
                <code>C35/45</code>
                <code>C40/50</code>
                <code>C45/55</code>
                <code>C50/60</code>
              </p>
            </CollapsibleBlock>
            <CollapsibleBlock title="PODSYPKA CEMENTOWO - PIASKOWA">
              <p>
                Podsypka cementowo-piaskowa jest to wilgotna mieszanina piasku i cementu. Produkujemy podsypki w zależności od potrzeb, w stosunku :
              </p>
              <p className="codesWrapper">
                <code>1:2</code>
                <code>1:3</code>
                <code>1:4</code>
                <code>1:5</code>
              </p>
            </CollapsibleBlock>
            <CollapsibleBlock title="STABILIZACJE I PODBUDOWY">
              <p>
                Stabilizacje i podbudowy – są to specjalistyczne mieszanki, o ściśle określonych proporcjach cementu, kruszyw mineralnych oraz wody, które wykorzystywane są jako podbudowy zasadnicze i pomocnicze nawierzchni drogowych.
              </p>
              <p>Produkcja stabilizacji następuje w czterech przedziałach:</p>
              <p className="codesWrapper">
                <code>1.5 – 2.5 MPa</code>
                <code>2.5 – 5.0 MPa</code>
                <code>6.0 – 9.0 MPa</code>
              </p>
            </CollapsibleBlock>
            <CollapsibleBlock title="KLOCKI BETONOWE">
              <p>
                <strong>Klocki betonowe</strong> to atrakcyjny produkt betonowy mający ogromy wachlarz zastosowań np :
              </p>
              <ul>
                <li>zasieki na materiały składowe</li>
                <li>ściany pod konstrukcję  </li>
                <li>ogrodzenia</li>
              </ul>
              <br />
              <p>
                Klocki betonowe to produkt który charakteryzuje się łatwością w wykonawstwie – nie potrzebujemy żadnego spoiwa.
              </p>
              <br />
              <p>
                Klocki betonowe są odpowiednio solidne i wytrzymałe na zewnętrzne warunki atmosferyczne oraz wszelkiego rodzaju uszkodzenia mechaniczne.
              </p>
              <br />
              <p>
                Klocki betonowe produkowane przez nas posiadają wymiary :
              </p>
              <ul>
                <li>
                  180cmx60cmx60cm
                </li>
                <li>
                  60x60x60 cm
                </li>
              </ul>
              <div className="imgBlocksWrapper">
                <img className="imgBlocks" src={Bloczki} alt="Klocki betonowe" />
              </div>

            </CollapsibleBlock>
          </div>
        </Container>
      </section>

      <FullMapSection />

    </div>
  )
}

export default OFirmieBetonPage;