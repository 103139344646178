import React from "react";
import "./main-header.scss";

interface Props {
  children: React.ReactNode;
  align?: 'left' | 'center' | 'right';
}

const MainHeader: React.FC<Props> = (props: Props) => {
  const { children, align } = props;
  return (
    <div className={`main-header ${align ? align : "center"}`} >
      {children}
    </div >
  )
}

export default MainHeader;