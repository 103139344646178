import React from "react";
import "./prefooter.scss";
import { RoutePath } from "../../../route-paths";
import TopLink from "../top-link/top-link";
import Container from "../container/container";
import PiwiosImg from "../../assets/piwios.svg"
import CzasNaDesignImg from "../../assets/czas-nas-design.svg"


interface Props {
}

const PreFooter: React.FC<Props> = (props: Props) => {

  return (
    <>
      <section className="prefooter" >
        <Container>
          <div className="column350">
            <div className="prefooterNavTitle">OFERTA</div>
            <TopLink to={RoutePath.BETON}>
              Beton
          </TopLink>
            <TopLink to={RoutePath.KRUSZYWA}>
              Kruszywa
          </TopLink>
            <TopLink to={RoutePath.USLUGITRANSPORTOWE}>
              Transport
          </TopLink>
            <TopLink to={RoutePath.USLUGITRANSPORTOWOSPRZETOWE}>
              Usługi sprzętowe
          </TopLink>
          </div>
          <div className="column350">
            <div className="prefooterNavTitle">REGULAMINY</div>
            <TopLink to={RoutePath.RULES}>
              Ochrona danych
          </TopLink>
            <TopLink to={RoutePath.RULES}>
              Regulamin
          </TopLink>
          </div>
          <div className="column350">
            <div className="prefooterNavTitle">KONTAKT</div>
            <TopLink to={RoutePath.BETON}>
              FTS Beton
            </TopLink>
            <p>ul. Południowa 1, 56-400 Oleśnica</p>
            <p>tel. <span>794 135 135</span></p>
            <p>email. <span>beton@ftsgrzyb.pl</span></p>
            <br />
            <br />
            <TopLink to={RoutePath.ARTURGRZYB}>
              FTS Artur Grzyb
            </TopLink>
            <p>ul. Południowa 1, 56-400 Oleśnica</p>
            <p>Właściciel: <span>605 421 668</span></p>
            <p>Biuro. <span>71 398 39 32</span></p>
            <p>email. <span>artur.grzyb@post.pl</span></p>
          </div>
          <div className="column350">
            <div className="prefooterNavTitle">NA SKRÓTY</div>            
            <TopLink to={RoutePath.ARTURGRZYB}>
            <a href="https://www.czasnadesign.pl" target="_blank" rel="noopener noreferrer">
            <img src={CzasNaDesignImg} alt="Czas na Design Angelika Grzyb" />
            </a>
            </TopLink>
          </div>
        </Container>
      </section>
    </>
  )
}

export default PreFooter;