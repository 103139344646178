/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useEffect, useState } from "react";
import "./fixed-action-buttons.scss";
import FbIcon from "./../../assets/fb.svg"
import MailIcon from "./../../assets/mail.svg"

interface Props {
}

const FixedActionButtons: React.FC<Props> = () => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setIsActive(true);
    }, 2500);
  }, [])

  return (
    <>
      <div className={isActive ? "fixed-action-buttons isActive" : "fixed-action-buttons"} >
        <a href="mailto: beton@ftsgrzyb.pl">
          <img src={MailIcon} alt="email" />
        </a>
        <a href="https://www.facebook.com/FTSBETON?locale=pl_PL">
          <img src={FbIcon} alt="facebook" />
        </a>
      </div>
    </>
  )
}

export default FixedActionButtons;