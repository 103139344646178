import React from "react";
import "./arturGrzyb-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/bg4.jpg";
import BgB from "../../assets/bg3.jpg";
import SubLogoA from "../../assets/ftsAG.jpg";
import ActionContactSection from "../../components/action-contact-section/action-contact-section";
import FullMapSection from "../../components/full-map-section/full-map-section";
import MainHeader from "../../components/main-header/main-header";
import HeroSection from "../../components/hero-section/hero-section";
import Container from "../../components/container/container";
import TextColImgColBlock from "../../components/textColImgCol-block/textColImgCol-block";
import OfferItem from "../../components/offer-item/offer-item";
import { RoutePath } from "../../../route-paths";

import Mountain from "../../assets/icons/mountainIcon.svg";
import DrogIcon from "../../assets/icons/drogowe.svg";
import NiskIcon from "../../assets/icons/niskopodlogowy.svg";
import RozbIcon from "../../assets/icons/rozbiorki.svg";
import TranIcon from "../../assets/icons/transport.svg";
import TsprIcon from "../../assets/icons/transsprzety.svg";
import UtylIcon from "../../assets/icons/utylizacja.svg";
import WodnIcon from "../../assets/icons/wodnokanalizacyjne.svg";
import ZiemIcon from "../../assets/icons/ziemne.svg";

const OFirmiePage: React.FC<any> = () => {

  return (
    <div className="arturGrzyb-page">
      <Helmet>
        <title>{'FTS Beton'}</title>
      </Helmet>

      <HeroSection bg={BgB}>
        <img className="subLogo" src={SubLogoA} alt="" />
      </HeroSection>

      <section>
        <Container>
          <MainHeader>
            O FIRMIE
          </MainHeader>
          <TextColImgColBlock img={BgA} reverse>
            FTS Artur Grzyb – Firma Transportowo-Sprzętowa została założona 1 lutego 1992 roku , przez właściciela Artura Grzyba , i do dziś nieprzerwanie działa pod nazwą Firma Transportowo-Sprzętowa Artur Grzyb ( FTS Artur Grzyb ) , stawiając na ciągły rozwój.
            Działalność firmy – jak sama nazwa wskazuje - rozpoczynała się od usług sprzętowych oraz transportowych.
            Dziś FTS Artur Grzyb  to firma już z wieloletnim doświadczeniem , którego wachlarz usług jest bardzo szeroki - świadczymy usługi transportowe , sprzętowe , posiadamy własne kopalnie kruszyw naturalnych , prowadzimy generalne wykonawstwo robót budowlanych , produkcję betonów jak i produktów betonowych Dzisiaj  Firma Transportowo-Sprzętowa Artur Grzyb to przede wszystkim doświadczenie , rzetelność oraz zaufanie do powierzonych nam prac , na które nieustannie pracujemy!
        </TextColImgColBlock>
        </Container>
      </section>
      <section>
        <Container isRaw>
          <MainHeader>
            OFERTA
          </MainHeader>
          <div className="offerItemsWrapper">
            <OfferItem
              name={<>KOPALNIE<br />KRUSZYWA</>}
              to={RoutePath.KRUSZYWA}
              icon={Mountain}
              iconAlt={"KOPALNIE KRUSZYWA"}
            />
            <OfferItem
              name={<>USŁUGI<br />UTYLIZACJI</>}
              to={RoutePath.USLUGIUTYLIZACJI}
              icon={UtylIcon}
              iconAlt={"USŁUGI UTYLIZACJI"}
            />
            <OfferItem
              name={"USŁUGI TRANSPORTOWE"}
              to={RoutePath.USLUGITRANSPORTOWE}
              icon={TranIcon}
              iconAlt={"USŁUGI TRANSPORTOWE"}
            />
            <OfferItem
              name={"WODNO KANALIZACYJNE"}
              to={RoutePath.USLUGIWODNOKANALIZACYJNE}
              icon={WodnIcon}
              iconAlt={"WODNO KANALIZACYJNE"}
            />
            <OfferItem
              name={"TRANSPORTOWO SPRZĘTOWE"}
              to={RoutePath.USLUGITRANSPORTOWOSPRZETOWE}
              icon={TsprIcon}
              iconAlt={"TRANSPORTOWO SPRZĘTOWE"}
            />
            <OfferItem
              name={<>ROBOTY<br />DROGOWE</>}
              to={RoutePath.ROBOTYDROGOWE}
              icon={DrogIcon}
              iconAlt={"ROBOTY DROGOWE"}
            />
            <OfferItem
              name={<>ROBOTY<br />ROZBIÓRKOWE</>}
              to={RoutePath.ROBOTYROZBIORKOWE}
              icon={RozbIcon}
              iconAlt={"ROBOTY ROZBIÓRKOWE"}
            />
            <OfferItem
              name={<>ROBOTY<br />ZIEMNE</>}
              to={RoutePath.ROBOTYZIEMNE}
              icon={ZiemIcon}
              iconAlt={"USŁUGI I ROBOTY ZIEMNE"}
            />
            <OfferItem
              name={"TRANSPORT NISKO-PODWOZIOWY"}
              to={RoutePath.TRANSPORTNISKOPODWOZIOWY}
              icon={NiskIcon}
              iconAlt={"TRANSPORT NISKO-PODWOZIOWY"}
            />
          </div>
        </Container>
      </section>
      <ActionContactSection />
      <FullMapSection />
    </div>
  )
}

export default OFirmiePage;