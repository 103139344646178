/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useRef, useState } from "react";
import "./collapsible-block.scss";
import { Collapse } from 'react-collapse';
import ArrowDownIcon from './../../assets/arrowDownIcon.svg';
import { handleScrollToSection } from "../../tools/handleScrollToSection";
interface Props {
  title: string,
  children: React.ReactNode;
  isOpenInit?: boolean;
}

const CollapsibleBlock: React.FC<Props> = (props: Props) => {
  const [isOpen, setIsOpen] = useState(props.isOpenInit ? true : false)
  const { title, children } = props;
  const ref = useRef<any>();

  const handleClickTitle = () => {
    if (!isOpen) {
      handleScrollToSection(ref)
    }
    setIsOpen(!isOpen);
  }

  return (
    <div ref={ref} className="collapsible-block">
      <div className="collapsible-block__title" onClick={() => handleClickTitle()}>
        {title}
        <img className={`imgIcon${isOpen ? " isOpen" : ""}`} src={ArrowDownIcon} alt="arrow" />
      </div>
      <Collapse isOpened={isOpen}>
        <div className="collapsible-block__content">
          {children}
        </div>
      </Collapse>
    </div>
  )
}

export default CollapsibleBlock;