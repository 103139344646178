import React, { useEffect } from "react";
import "./uslugi-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/rozb.jpg";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import { useDispatch } from "react-redux";
import { setNavbarBar } from "../../redux/callbacksState/callbacksState.actions";
import Container from "../../components/container/container";
import TextColImgColBlock from "../../components/textColImgCol-block/textColImgCol-block";
import RedHeroSection from "../../components/redHero-section/redHero-section";

import RozbIcon from "../../assets/icons/rozbiorki.svg";

const RozbPage: React.FC<any> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarBar(true));
    return () => {
      dispatch(setNavbarBar(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="uslugi-page">
      <Helmet>
        <title>{'FTS Artur Grzyb | ROBOTY ROZBIÓRKOWE'}</title>
      </Helmet>
      <RedHeroSection icon={RozbIcon} iconAlt={"ROBOTY ROZBIÓRKOWE"}>
        <TopLink to={RoutePath.ARTURGRZYB}>
          <p className="heroPath">FTS ARTUR GRZYB /</p>
        </TopLink>
        <h2 className="heroTitle">ROBOTY ROZBIÓRKOWE</h2>
      </RedHeroSection>
      <Container>
        <section>
          <TextColImgColBlock img={BgA} alignTop={true}>
            <p>
            Kompleksowo zajemujemy się rozbiórką dróg i budynków. Specjalistyczny sprzęt i wykwalifikowany personel jest w stanie dokładnie, ostrożnie i z zachowaniem wszystkich norm BHP dokonać rozbiórki wskazanego elementu.
            </p>
          </TextColImgColBlock>
        </section>
      </Container>
    </div>
  )
}

export default RozbPage;