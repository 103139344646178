import React, { } from "react";
import "./full-map-section.scss";

interface Props {
}

const FullMapSection: React.FC<Props> = () => {
  return (
    <section className="full-map-section" >
      <iframe className="map" title="map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2500.0327590165784!2d17.377954416070892!3d51.200048040949326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470fe2e4c62ffe25%3A0x5deecf2ce314e50b!2sPo%C5%82udniowa%201%2C%2056-400%20Ole%C5%9Bnica!5e0!3m2!1spl!2spl!4v1602863371522!5m2!1spl!2spl" frameBorder="0" style={{ border: 0 }} allowFullScreen={false} aria-hidden="false" tabIndex={0}></iframe>
    </section>
  )
}

export default FullMapSection;