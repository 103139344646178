/* eslint-disable jsx-a11y/anchor-has-content */
import React, { } from "react";
import "./footer.scss";
import IdeeasLogo from "./../../assets/ideeas.svg"
import Container from "../container/container";

interface Props {
}

const Footer: React.FC<Props> = () => {

  return (
    <>
      <section className="footer">
        <Container>
          <div className="leftCol">© FTS Beton</div>
          <div className="rightCol"><a href="https://ideeas.pl">
            <img src={IdeeasLogo} alt="" />
          </a></div>
        </Container>
      </section>
    </>
  )
}

export default Footer;