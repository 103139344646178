/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import TopLink from "../top-link/top-link";
import "./offer-item.scss";

interface Props {
  name: string | React.ReactNode;
  to: string;
  icon: string;
  iconAlt?: string;
}

const OfferItem: React.FC<Props> = (props: Props) => {
  const { name, to, icon, iconAlt } = props;
  return (
    <TopLink to={to}>
      <div className="offer-item">
        <img src={icon} alt={iconAlt ? iconAlt : ""} />
        <div className="offerItem__name">{name}</div>
        <div className="offerItem__more">
          więcej &rarr;
    </div>
      </div>
    </TopLink>
  )
}

export default OfferItem;