import React, { useEffect } from "react";
import "./uslugi-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/transNisk.jpg";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";
import { useDispatch } from "react-redux";
import { setNavbarBar } from "../../redux/callbacksState/callbacksState.actions";
import Container from "../../components/container/container";
import TextColImgColBlock from "../../components/textColImgCol-block/textColImgCol-block";
import RedHeroSection from "../../components/redHero-section/redHero-section";

import NiskIcon from "../../assets/icons/niskopodlogowy.svg";


const TransportNiskPage: React.FC<any> = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setNavbarBar(true));
    return () => {
      dispatch(setNavbarBar(false))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  return (
    <div className="uslugi-page">
      <Helmet>
        <title>{'FTS Artur Grzyb | TRANSPORT NISKOPODWOZIOWY'}</title>
      </Helmet>
      <RedHeroSection icon={NiskIcon} iconAlt={"TRANSPORT NISKOPODWOZIOWY"}>
        <TopLink to={RoutePath.ARTURGRZYB}>
          <p className="heroPath">FTS ARTUR GRZYB /</p>
        </TopLink>
        <h2 className="heroTitle">TRANSPORT NISKOPODWOZIOWY</h2>
      </RedHeroSection>
      <Container>
        <section>
          <TextColImgColBlock img={BgA}>
            <p>
              Transport niskopodwoziowy charakteryzuje się przewozem materiałów i elementów wielkogabarytowych i niewymiarowych.
            </p>
          </TextColImgColBlock>
        </section>
      </Container>
    </div>
  )
}

export default TransportNiskPage;