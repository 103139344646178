import React, { ReactNode } from "react";
import "./navbar.scss";
import LogoImg from "./../../assets/logo.png";
import { RoutePath } from "../../../route-paths";
import TopLink from "../top-link/top-link";
import { select_is_navbarBarActive, select_is_navbarOpenActive } from "../../redux/callbacksState/callbacksState.selectors";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { setNavbarOpen } from "../../redux/callbacksState/callbacksState.actions";


interface Props {
  isSubNavHidden?: boolean;
  children?: ReactNode
}

const Navbar: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch()

  const is_navbarBarActive = useSelector(select_is_navbarBarActive, shallowEqual);
  const is_navbarOpenActive = useSelector(select_is_navbarOpenActive, shallowEqual);

  const setNavExpanded = (isOpen: boolean) => {
    dispatch(setNavbarOpen(isOpen));
  }

  return (
    <>
      <div className={`navbarBar${is_navbarBarActive ? " navbarbarActive" : ""}`}></div>

      <div className={`navbarLogo${is_navbarBarActive ? " navbarbarActive" : ""}`} onClick={() => setNavExpanded(false)}>
        <TopLink to={RoutePath.HOME}>
          <img src={LogoImg} alt="LogoImg" />
        </TopLink>
      </div>
      <div className={`menuBtn${is_navbarOpenActive ? " isActive" : ""}${is_navbarBarActive ? " navbarbarActive" : ""}`} onClick={() => setNavExpanded(!is_navbarOpenActive)}>
        <span className="top" />
        <span className="mid" />
        <span className="bot" />
      </div>
      <div className="mobileMenuWrapper">
        <div className={`mobileSideMenuOverlay ${is_navbarOpenActive ? "isActive" : ""}`} onClick={() => setNavExpanded(false)}></div>
        <div className={`mobileSideMenu ${is_navbarOpenActive ? "isActive" : ""}`} >
          <div onClick={() => setNavExpanded(false)}>
            <br />
            <br />
            <TopLink to={RoutePath.ARTURGRZYB}>
              <div className="linkLarge" >FTS Artur Grzyb</div>
            </TopLink>
            <TopLink to={RoutePath.ROBOTYZIEMNE}>
              <div className="linkSmall">Roboty ziemne</div>
            </TopLink>
            <TopLink to={RoutePath.ROBOTYROZBIORKOWE}>
              <div className="linkSmall">Roboty rozbiórkowe</div>
            </TopLink>
            <TopLink to={RoutePath.ROBOTYDROGOWE}>
              <div className="linkSmall">Roboty drogowe</div>
            </TopLink>
            <TopLink to={RoutePath.USLUGIWODNOKANALIZACYJNE}>
              <div className="linkSmall">Roboty wodno-kanalizacyjne</div>
            </TopLink>
            <TopLink to={RoutePath.USLUGIUTYLIZACJI}>
              <div className="linkSmall">Utylizacje odpadów</div>
            </TopLink>
            <TopLink to={RoutePath.USLUGITRANSPORTOWOSPRZETOWE}>
              <div className="linkSmall">Usługi sprzętowe</div>
            </TopLink>
            <TopLink to={RoutePath.USLUGITRANSPORTOWE}>
              <div className="linkSmall">Usługi transportowe</div>
            </TopLink>
            <TopLink to={RoutePath.KRUSZYWA}>
              <div className="linkSmall">Kopalnie kruszywa</div>
            </TopLink>
            <TopLink to={RoutePath.TRANSPORTNISKOPODWOZIOWY}>
              <div className="linkSmall">Transport nisko-podwoziowy</div>
            </TopLink>
            <TopLink to={RoutePath.BETON}>
              <div className="linkLarge">FTS Beton</div>
            </TopLink>
          </div>
        </div>
      </div>
    </>
  )
}

export default Navbar;