import React from "react";
import "./home-page.scss";
import Helmet from "react-helmet";
import BgA from "../../assets/bg1.jpg";
import BgB from "../../assets/bg2.jpg";
import SubLogoA from "../../assets/ftsAG.jpg";
import SubLogoB from "../../assets/ftsBeton.jpg";
import TopLink from "../../components/top-link/top-link";
import { RoutePath } from "../../../route-paths";

const HomePage: React.FC<any> = () => {

  return (
    <div className="home-page">
      <Helmet>
        <title>{'FTS Beton'}</title>
      </Helmet>
      <section className="heroSection">
        <div className="leftHero" style={{ backgroundImage: `url(${BgA})` }}>
          <TopLink to={RoutePath.ARTURGRZYB}>
            <img className="subLogo" src={SubLogoA} alt="" />
          </TopLink>
        </div>
        <div className="rightHero" style={{ backgroundImage: `url(${BgB})` }}>
          <TopLink to={RoutePath.BETON}>
            <img className="subLogo" src={SubLogoB} alt="" />
          </TopLink>
        </div>
      </section>
    </div>
  )
}

export default HomePage;